<template>
  <div class="pt-10">
    <div class="vx-col w-auto">
      <vx-card>
        <div class="flex items-center justify-between">
          <div class="vx-breadcrumb md:block hidden">
            <ul class="flex flex-wrap items-center">
              <li class="inline-flex items-center">
                <!-- <router-link :to="{ name: 'director-learning-centre-list' }"
                  >
                  Manage</router-link
                > -->
                <a @click="$router.go(-1)" style="cursor:pointer">
                  Manage
                </a>
                <span class="breadcrumb-separator mx-2">
                  <span class="feather-icon select-none relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-chevrons-right"
                    >
                      <polyline points="13 17 18 12 13 7" />
                    </svg>
                  </span>
                </span>
              </li>
              <li class="inline-flex items-center">
                <router-link
                  :to="{
                    name: 'director-learning-centre-view-list',
                    params: { type: 'teachers', id: learningCenterId }
                  }"
                  >Teachers
                </router-link>
                <span class="breadcrumb-separator mx-2">
                  <span class="feather-icon select-none relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-chevrons-right"
                    >
                      <polyline points="13 17 18 12 13 7" />
                    </svg>
                  </span>
                </span>
              </li>

              <li class="inline-flex items-center">
                <a href="javascript:void(0)" class>Add New Teacher</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="w-full md:w-1/2">
          <div class="vx-col mt-5 vs-con-loading__container">
            <template v-if="logo">
              <!-- Image Container -->
              <div class="img-container w-64 flex items-center">
                <img :src="logo" alt="img" class="responsive" />
              </div>

              <!-- Image upload Buttons -->
              <div class="modify-img flex my-8">
                <input
                  type="file"
                  class="hidden"
                  ref="updateImgInput"
                  @change="updateCurrImg"
                  accept="image/*"
                />
                <vs-button
                  type="border"
                  class="mr-4 btn-blue-border"
                  @click="$refs.updateImgInput.click()"
                  >Update Photo
                </vs-button>
                <vs-button color="danger" @click="logo = null"
                  >Remove Photo</vs-button
                >
              </div>
            </template>

            <div class="upload-img my-8" v-if="!logo">
              <input
                type="file"
                class="hidden"
                ref="uploadImgInput"
                @change="updateCurrImg"
                accept="image/*"
              />
              <vs-button
                type="border"
                class="btn-blue-border"
                @click="$refs.uploadImgInput.click()"
                >Upload Photo
              </vs-button>
            </div>
          </div>
          <!-- Room -->
          <div class="vx-col w-full mt-5">
            <div class="select-wrapper">
              <div class="vs-component is-label-placeholder">
                <v-select
                  :options="roomOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="roomFilter"
                  class="mb-4 md:mb-0"
                  data-vv-as="Room"
                >
                  <template #header>
                    <label for class="label">Room</label>
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <vs-icon icon="arrow_drop_down"></vs-icon>
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
          </div>

          <!-- Title -->
          <div class="vx-col w-full mt-5">
            <div class="select-wrapper">
              <div class="vs-component is-label-placeholder">
                <v-select
                  :options="titleOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="titleFilter"
                  class="mb-4 md:mb-0"
                  data-vv-as="Title"
                >
                  <template #header>
                    <label for class="label">Title</label>
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <vs-icon icon="arrow_drop_down"></vs-icon>
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="vx-col w-full mt-5">
            <ul class="centerx">
              <li style="display: inline-block; margin-right: 10px">
                <vs-radio v-model="role" vs-value="teacher">Teacher</vs-radio>
              </li>
              <li style="display: inline-block">
                <vs-radio v-model="role" vs-value="leadTeacher"
                  >Lead Teacher</vs-radio
                >
              </li>
            </ul>
          </div>
          <vs-input
            label-placeholder="Full Name"
            v-model="fullName"
            class="mt-5 w-full"
            name="fullName"
            v-validate="'required'"
            data-vv-as="Full Name"
          />
          <span class="text-danger text-sm" v-show="errors.has('fullName')">{{
            errors.first("fullName")
          }}</span>

          <vs-input
            class="w-full mt-5"
            v-model="email"
            name="email"
            v-validate="'required|email'"
            label-placeholder="Email"
            data-vv-as="Email"
          />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{
            errors.first("email")
          }}</span>

          <vs-input
            label-placeholder="Mobile Number"
            v-model="contactNumber"
            class="mt-5 w-full"
            name="contactNumber"
            v-validate="'required|numeric'"
            data-vv-as="Mobile Number"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('contactNumber')"
            >{{ errors.first("contactNumber") }}</span
          >
          <div class="vx-col w-full mt-5">
            <div class="select-wrapper">
              <div class="vs-component is-label-placeholder">
                <v-select
                  :options="countryOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="countryFilter"
                  class="mb-4 md:mb-0"
                  data-vv-as="Country"
                >
                  <template #header>
                    <label for class="label">Country</label>
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <vs-icon icon="arrow_drop_down"></vs-icon>
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="vx-col w-full mt-5">
            <div class="select-wrapper">
              <div class="vs-component is-label-placeholder">
                <v-select
                  :options="stateOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="stateFilter"
                  class="mb-4 md:mb-0"
                  data-vv-as="State"
                >
                  <template #header>
                    <label for class="label">State</label>
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <vs-icon icon="arrow_drop_down"></vs-icon>
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <vs-input
            label-placeholder="Street Address"
            v-model="streetAddress"
            class="mt-5 w-full"
            name="streetAddress"
            data-vv-as="Street Address"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('streetAddress')"
            >{{ errors.first("streetAddress") }}</span
          >

          <vs-input
            label-placeholder="Town/City"
            v-model="town"
            class="mt-5 w-full"
            name="town"
            data-vv-as="Town"
          />
          <span class="text-danger text-sm" v-show="errors.has('town')">{{
            errors.first("town")
          }}</span>
          <vs-input
            label-placeholder="Postcode"
            v-model="postCode"
            class="mt-5 w-full"
            name="postCode"
            data-vv-as="Post Code"
          />
          <span class="text-danger text-sm" v-show="errors.has('postCode')">{{
            errors.first("postCode")
          }}</span>
        </div>
        <div class="w-full mt-5">
          <label class="vs-input--label">Account Status</label>
          <vs-switch v-model="status" />
          {{ status ? "Active" : "Inactive" }}
        </div>
        <div class="w-full md:w-1/2 mt-5">
          <div class="flex flex-wrap items-center justify-end">
            <vs-button color="danger" @click="cancelBtn" class="mr-8"
              >Cancel</vs-button
            >
            <vs-button @click="submitData" :disabled="!isFormValid"
              >Save</vs-button
            >
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dataId: null,
      directorId: "",
      titleOptions: [
        { label: "Mr.", value: "Mr." },
        { label: "Mrs.", value: "Mrs." },
        { label: "Miss.", value: "Miss." }
      ],
      fullName: "",
      contactNumber: "",
      homeNumber: "",
      workNumber: "",
      role: "teacher",
      email: "",
      logo: null,
      image: null,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      streetAddress: "",
      town: "",
      postCode: "",
      status: true,
      roomOptions: [],
      countryOptions: [],
      stateOptions: [],
      roomFilter: { label: "Select room", value: "" },
      titleFilter: { label: "Select Title", value: "" },
      countryFilter: { label: "All", value: "all" },
      stateFilter: { label: "All", value: "all" },
      customError: "",
      learningCenterId: null
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    centerId: {
      type: String,
      default: ""
    }
  },
  watch: {
    countryFilter(obj) {
      this.stateOptions = obj.states;
    }
  },
  computed: {
    isFormValid() {
      return !this.errors.any() && this.fullName && this.email;
    },
    director() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    ...mapActions("center", [
      "updateDirectorTeacher",
      "addDirectorTeacher",
      "imageUpload",
      "getDirectorAllCountries",
      "getDirectorRooms",
      "getLearningCenterRooms"
    ]),
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        var reader = new FileReader();
        reader.onload = e => {
          this.logo = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    async submitData() {
      await this.$validator.validateAll().then(result => {
        if (result) {
          let data = new FormData();
          data.append("id", this.dataId);
          data.append("room", this.roomFilter.value);
          data.append("title", this.titleFilter.value);
          data.append("fullName", this.fullName);
          data.append("contactNumber", this.contactNumber);
          data.append("streetAddress", this.streetAddress);
          data.append("town", this.town);
          data.append("postCode", this.postCode);
          data.append("state", this.stateFilter.value);
          data.append("directorId", this.director.id);
          data.append("email", this.email);
          data.append("logo", this.image);
          data.append("role", this.role);
          data.append("status", this.status);

          if (null != this.learningCenterId) {
            data.append("learningCenterId", this.learningCenterId);
          } else {
            data.append("learningCenterId", undefined);
          }
          this.customError = "";
          if (this.dataId !== null) {
            this.$vs.loading();
            this.updateDirectorTeacher(data)
              .then(res => {
                if (res.status === 200) {
                  this.$vs.notify({
                    title: "Success",
                    text: "Teacher updated Successfully",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    color: "success"
                  });
                  data.country = this.countryFilter.value;
                  this.$emit("refreshData", true);
                }
                this.$vs.loading.close();
              })
              .catch(err => {
                this.$vs.loading.close();
              });
          } else {
            this.$vs.loading();
            this.addDirectorTeacher(data)
              .then(async res => {
                if ((await res.status) === 200) {
                  this.$vs.loading.close();
                  if (null != this.learningCenterId) {
                    this.$router.push({
                      name: "director-learning-centre-view-list",
                      params: { type: "teachers", id: this.learningCenterId }
                    });
                  } else {
                    this.$router
                      .push({ name: "director-teachers-list" })
                      .catch(() => {});
                  }

                  this.$emit("refreshData", true);
                  this.$vs.notify({
                    title: "Success",
                    text: "Teacher added Successfully",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    color: "success"
                  });
                }
              })
              .catch(err => {
                this.$vs.loading.close();
                if (err.response.status === 422) {
                  this.errors.add({
                    field: "email",
                    msg: err.response.data.message
                  });
                } else {
                  this.$vs.notify({
                    title: "Failure",
                    text: "Can not add teacher",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    color: "danger"
                  });
                }
              });
          }
        }
      });
    },
    cancelBtn() {
      this.$router.push({ name: "director-teachers-list" }).catch(() => {});
    }
  },
  created() {
    if ("undefined" != typeof this.$route.params.id) {
      this.learningCenterId = this.$route.params.id;
    } else {
      this.learningCenterId = null;
    }
    this.getDirectorAllCountries()
      .then(res => {
        this.countryOptions = this.$store.state.center.countryOptions;
      })
      .catch(err => {});
    this.getLearningCenterRooms((this.learningCenterId = this.$route.params.id))
      .then(res => {
        this.roomOptions = res.data.data;
      })
      .catch(err => {});
    // this.getDirectorRooms(this.director.id)
    //   .then(res => {
    //     this.roomOptions = res.data.data;
    //   })
    //   .catch(err => {});
  },
  components: {
    vSelect
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
